import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedProgressBarService {

  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null!);
  public isLoading$: Observable<boolean> = this._isLoading.asObservable();


  private _isOrganizationProgressbarLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null!);
  public isOrganizationProgressbarLoading$: Observable<boolean> = this._isOrganizationProgressbarLoading.asObservable();


  private _isCalendarProgressbarLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null!);
  public isCalendarProgressbarLoading$: Observable<boolean> = this._isCalendarProgressbarLoading.asObservable();


  public set(isLoading: boolean) {
    this._isLoading.next(isLoading);
  }

  public setOrganizationProgressBar(isLoading: boolean) {
    this._isOrganizationProgressbarLoading.next(isLoading);
  }

  public setCalendarProgressBar(isLoading: boolean) {
    this._isCalendarProgressbarLoading.next(isLoading);
  }
}



